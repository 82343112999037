import React, { useState, useEffect } from 'react'

interface MediaQueryProps {
	[key: string]: string
}

const useMediaQuery = (breakpoints: MediaQueryProps) => {
	const [queryMatches, setQueryMatches] = useState(false)

	useEffect(() => {
		const mediaQueryLists: MediaQueryList[] = Object.keys(breakpoints).map(
			breakpoint =>
				window.matchMedia(`(min-width: ${breakpoints[breakpoint]})`),
		)

		const checkMediaQueries = () => {
			const matches = mediaQueryLists.some(mediaQuery => mediaQuery.matches)
			setQueryMatches(matches)
		}

		mediaQueryLists.forEach(mediaQuery => {
			mediaQuery.addEventListener('change', checkMediaQueries)
		})

		// Initial check
		checkMediaQueries()

		return () => {
			mediaQueryLists.forEach(mediaQuery => {
				mediaQuery.removeEventListener('change', checkMediaQueries)
			})
		}
	}, [breakpoints])

	return queryMatches
}

export default useMediaQuery








export function useMediaQueryFromPartner(query: string) {
	const [value, setValue] = React.useState(false)

	React.useEffect(() => {
		function onChange(event: MediaQueryListEvent) {
			setValue(event.matches)
		}

		const result = matchMedia(query)
		result.addEventListener('change', onChange)
		setValue(result.matches)

		return () => result.removeEventListener('change', onChange)
	}, [query])

	return value
}